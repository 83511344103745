.App {
  text-align: center;
  min-height:100vh;
  background-color: #ffffff;
}

.social_div{
  padding-top: 20px;
}

.App-logo {
  max-height: 70vh;
  padding-top: 20vh;
  top:0;
  right:0;
  left:0;
  max-width: 90vw;
}

.instagram-logo {
  height: 60px;
  left:0;
  right:0;
  bottom:0;
  width: 60px;
  padding: 10px;
}

.facebook-logo {
  height: 60px;
  left:0;
  right:0;
  bottom: 10px;
  width: 60px;
  padding: 10px;
}

p{
  padding:0;
  margin:0;
}

.App-link {
  color: #000000;
  text-decoration: none;
}

.App-body {
  position:absolute;
  left:0;
  right:0;
  bottom:0px;
  font-size: larger;
}
